<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <b-icon v-if="!current" icon="folder" />
        <b-icon v-if="current" icon="folder-fill" />
        <span class="ml-2">{{ folder.name }}</span>
      </div>
      <div v-if="folder.id" class="ml-1">
        <b-icon :id="`popover-${folder.id}`" class="dots" icon="three-dots-vertical" />
        <b-popover
          :target="`popover-${folder.id}`"
          placement="left"
          triggers="hover"
        >
          <b-button-group>
            <b-button variant="primary" size="sm" @click="$emit('edit', folder)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" size="sm" @click="$emit('del', folder.id)">
              <b-icon icon="trash-fill" aria-hidden="true" />
            </b-button>
          </b-button-group>
        </b-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Folder',
  props: {
    folder: {
      type: Object,
      default: () => ({}),
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    }
  },

}
</script>

<style lang="scss" scoped>
  .dots {
    outline: none;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
