<template>
  <div>
    <b-modal id="modal-1" size="lg" :title="isUpdate ? 'Редактирование файла':'Загрузка файла'" hide-footer>
      <b-form size="sm" @submit.prevent="createFile">
        <b-form-group label="Название:">
          <b-input v-model="form.name" required />
        </b-form-group>
        <b-form-group label="Файл:" label-cols-sm="4" label-cols-lg="4">
          <b-input-group>
            <b-form-input
              v-if="!uploadSelected"
              v-model="form.url"
              readonly="readonly"
              size="sm"
            />
            <b-form-file
              ref="filebrowse"
              v-model="file"
              size="sm"
              :style="(uploadSelected && !uploadInProgress)?'':'display:none; visibility:hidden'"
              browse-text="Выбрать"
              :state="Boolean(file)"
              placeholder="Выберите файл"
              drop-placeholder="Перетащите файл..."
            />
            <b-progress v-if="uploadInProgress" :value="percent_done" max="100" show-progress animated style="width: 100%;" />
            <b-input-group-append v-if="!uploadSelected">
              <b-button size="sm" variant="info" @click="selectUpload">
                <b-icon-upload />
                Загрузить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Папка:" label-cols-sm="4" label-cols-lg="4">
          <b-form-select v-model="form.folder.selectId" :options="selectOptions" size="sm" />
        </b-form-group>
        <b-alert v-model="error" dismissible variant="danger">
          {{ message }}
        </b-alert>
        <loading-button :is-busy="isBusy" :text="isUpdate ? 'Сохранить':'Создать'" />
      </b-form>
    </b-modal>
    <b-modal id="modal-folder" size="sm" :title="(isUpdateFolder ? 'Изменить':'Создать') + ' папку'" hide-footer>
      <b-form v-if="!isUpdateFolder" size="sm" @submit.prevent="createFolder">
        <b-form-group label="Имя папки:">
          <b-input v-model="form.folder.name" required />
        </b-form-group>
        <b-button type="submit">
          {{ isUpdateFolder ? 'Изменить':'Создать' }}
        </b-button>
      </b-form>
      <b-form v-if="isUpdateFolder" size="sm" @submit.prevent="updateFolder">
        <b-form-group label="Имя папки:">
          <b-input v-model="form.folder.name" required />
        </b-form-group>
        <b-form-group label="Переместить в папку:">
          <b-form-select v-model="form.folder.selectId" :options="selectOptions" size="sm" />
        </b-form-group>
        <b-button type="submit">
          {{ isUpdateFolder ? 'Изменить':'Создать' }}
        </b-button>
      </b-form>
    </b-modal>

    <!-- buttons -->
    <b-row align-h="between">
      <b-col>
        <b-button v-b-modal.modal-folder class="mb-3 mr-2" @click="onCreateFolder">
          Добавить папку
        </b-button>
        <b-button v-b-modal.modal-1 class="mb-3" @click="onCreateFile">
          Добавить файл
        </b-button>
      </b-col>
      <b-col cols="2" class="text-right">
        <LoadingButton
          variant="outline-secondary"
          text="Выгрузить"
          type="button"
          :is-busy="isExporting"
          @click.native="exportToExcel"
        />
      </b-col>
    </b-row>

    <!-- folders -->
    <b-row>
      <b-col>
        <b-list-group>
          <b-list-group-item variant="secondary" button @click="setCurrentFolder(parentFolder)">
            <div class="d-flex justify-content-between">
              <div>
                <b-icon icon="folder-fill" />
                <span class="ml-2">{{ currentFolder.name }}</span>
              </div>
              <div>
                <b-icon v-if="currentFolderId !== null" animation="throb" icon="arrow90deg-up" />
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item v-for="(item) in subFolders" :key="item.id" button @click="setCurrentFolder(item)">
            <Folder :folder="item" :current="item.id == currentFolderId" @del="removeFolder" @edit="onUpdateFolder" />
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

    <!-- table -->
    <b-row class="mt-3">
      <b-col>
        <b-table
          ref="table"
          head-variant="light"
          bordered
          hover
          show-empty
          small
          :items="tableDataProvider"
          :fields="fields"
          :busy.sync="isBusy"
          empty-text="Нет файлов"
          empty-filtered-text="Не найдено"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:cell(url)="data">
            <a :href="data.value" target="_blank">{{ getPathName(data.value) }}</a>
          </template>
          <template v-slot:cell(size)="data">
            {{ (data.item.size / 1024).toLocaleString('ru-RU', { maximumFractionDigits: 0 }) }}
          </template>
          <template v-slot:cell(date)="data">
            {{ new Date(data.item.date).toLocaleDateString(['ru-RU']) }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button-group>
              <b-button :disabled="!data.item.editable" variant="primary" size="sm" @click="edit(data.item)">
                <b-icon-pencil />
              </b-button>
              <b-button :disabled="!data.item.editable" variant="danger" size="sm" @click="remove(data.item.id)">
                <b-icon icon="trash-fill" aria-hidden="true" />
              </b-button>
              <b-button variant="secondary" size="sm" @click="lockToggle(data.item.id)">
                <b-icon v-if="!data.item.editable" icon="lock-fill" aria-hidden="true" />
                <b-icon v-if="data.item.editable" icon="unlock-fill" aria-hidden="true" />
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          align="center"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoadingButton from '../components/LoadingButton'
import Folder from '../components/Folder'

export const part_size = 10 * 1024 * 1024 // чанки по 10 мб
export default {
  name: 'Files',
  components: {LoadingButton, Folder},
  data() {
    return {
      isUpdate: false,
      isUpdateFolder: false,
      file: null,
      uploadSelected: false,
      uploadInProgress: false,
      reader: null,
      chunkNum: 1,
      parts: {Parts: []},
      key: '',
      upload_id: '',
      percent_done: 0,

      playerOptions: {
        fluid: true,
        muted: false,
        language: 'ru',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
      },
      searchQuery: {
        query: null,
        tags: [],
      },
      isExporting: false,
      isBusy: false,
      isBusyCreate: false,
      totalRows: 1,
      errorResult: null,
      messageResult: '',
      perPage: 100,
      currentPage: 1,
      error: false,
      message: '',
      fields: [
        {key: 'id', label: '#', tdClass: 'text-center align-middle', thClass: 'text-center align-middle'},
        {key: 'name', label: 'Название', thClass: 'text-center align-middle', tdClass: 'align-middle'},
        {key: 'url', label: 'Файл', thClass: 'text-center align-middle', tdClass: 'align-middle'},
        {key: 'size', label: 'кБайтов',tdClass: 'text-center align-middle', thClass: 'text-center align-middle'},
        {key: 'downloads', label: 'Скачано',tdClass: 'text-center align-middle', thClass: 'text-center align-middle'},
        {key: 'date', label: 'Дата', tdClass: 'text-center align-middle', thClass: 'text-center align-middle'},
        {key: 'actions', label: 'Действия', tdClass: 'text-center align-middle',thClass: 'text-center align-middle'},
      ],
      items: [],
      form: {
        name: '',
        url: '',
        id: '',
        size: null,
        folder: {
          name: '',
          selectId: null,
          parentId: null,
        },
      },
      folders: [],
      currentFolderId: null,
    isShowFilters: false,
    }
  },
  computed: {
    subFolders() {
      return this.folders.filter(item => item.parentId == this.currentFolderId)
    },
    currentFolder() {
      let folder = {}
      if (this.currentFolderId == null) {
        folder = {name: 'Корневая папка'}
      } else {
        folder = this.folders.find(item => item.id == this.currentFolderId)
      }
      return folder
    },
    parentFolder() {
      let parent = {}
      if (this.currentFolder.parentId == null) {
        parent = {id: null, parentId: null, name: 'Корневая папка'}
      } else {
        parent = this.folders.find(item => item.id == this.currentFolder.parentId)
      }
      return parent
    },
    selectOptions() {
      return [{value: null, text: 'Корневая папка'}, ...this.folders.map((item) => ({value: item.id, text: item.name}))]
    },
  },
  watch: {
    'file':
      {
        handler: function(data) {
          if (data) {
            this.form.size = this.file.size
            this.startUpload()
          }
        },
      },
  },
  methods: {
    async exportToExcel() {
      this.isExporting = true
      try {
        const response = await this.axios.get(process.env.VUE_APP_BACKEND_URL + '/admin/api/files/export', {...this.$store.getters.getAxiosConfig, responseType: 'blob'})

        if (response.data?.error)
          console.log('error: ', response.data.message)

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        const fileName = `files-${new Date().toLocaleString(['ru-RU'])}.xlsx`

        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.isExporting = false
      } catch (error) {
        console.log('error: ', error)
        this.isExporting = false
      }
    },
    lockToggle(id) {
      this.axios.get(process.env.VUE_APP_BACKEND_URL + '/admin/api/files/' + id + '/lock', this.$store.getters.getAxiosConfig).then((response) => {
        if (response) {
          if (response.data.error === false) {
            this.error = false
            this.items.find(item => {
              if (item.id == id) {
                item.editable = !item.editable
              }
            })
          } else {
            console.log(response.data.message)
          }
        }
      })    },
    getPathName(url){
      let el = document.createElement('a')
      el.href = url

      let result = el.pathname
      let pathname = el.pathname.split('/')
      if (pathname.length > 3) {
        pathname.splice(1, 1)
        result = pathname.join('/')
      }

      return result
    },
    setCurrentFolder(item) {
       this.currentFolderId = item.id
       this.form.folder.parentId = item.parentId == null ? null : item.parentId
       this.searchQuery.query = item.id
       this.$refs.table.refresh()
    },
    onCreateFolder() {
      this.isUpdateFolder = false
        this.form.folder.name = ''
    },
    async createFolder(e) {
      e.preventDefault()
      try {
        const response = await this.axios.post(
          process.env.VUE_APP_BACKEND_URL +
          '/admin/api/folders',
          {
            name: this.form.folder.name,
            parentId: this.currentFolderId,
          },
          this.$store.getters.getAxiosConfig,
        )
        if (response.data.error)
            throw new Error(response.data.message)
        this.$bvModal.hide('modal-folder')
        this.$refs.table.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    onUpdateFolder(folder) {
      this.isUpdateFolder = true
      this.form.folder.id = folder.id
      this.form.folder.patentId = folder.parentId
      this.form.folder.selectId = folder.parentId
      this.form.folder.name = folder.name
      this.$bvModal.show('modal-folder')
    },
    async updateFolder() {
      try {
        const response = await this.axios.put(
          process.env.VUE_APP_BACKEND_URL +
          '/admin/api/folders/' + this.form.folder.id,
          {
            name: this.form.folder.name,
            parentId: this.form.folder.selectId,
          },
          this.$store.getters.getAxiosConfig,
        )
        if (response.data.error)
            throw new Error(response.data.message)
        this.$bvModal.hide('modal-folder')
        this.$refs.table.refresh()
      } catch (error) {
        console.log(error)
      }
    },
    async removeFolder(id) {
      try {
        const confirm = await this.$bvModal.msgBoxConfirm('Папка и все ее содержимое будет удалено, включая подкаталоги и файлы на всех уровнях вложенности.', {
          title: 'Внимание!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })

        if (!confirm) return

        const response = await this.axios.delete(
          process.env.VUE_APP_BACKEND_URL +
          '/admin/api/folders/' + id,
          this.$store.getters.getAxiosConfig,
        )
        if (response.data.error)
            throw new Error(response.data.message)
        this.$refs.table.refresh()
        console.log('folders: ', response.data.folders)
      } catch (error) {
        console.log(error)
      }
    },
    onCreateFile() {
      this.isUpdate = false
      this.form.url = ''
      this.form.name = ''
      this.form.id = null
      this.form.folder.selectId = this.currentFolderId
    },
    createFile(evt) {
      evt.preventDefault()
      this.error = false
      this.isBusyCreate = true
      let promise
      if (this.isUpdate) {
        promise = this.axios.put(process.env.VUE_APP_BACKEND_URL + '/admin/api/files', this.form, this.$store.getters.getAxiosConfig)
      } else {
        promise = this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/files', this.form, this.$store.getters.getAxiosConfig)
      }
      promise.then((response) => {
        this.isBusyCreate = false
        if (response) {
          if (response.data.error === false) {
            this.$refs.table.refresh()
            this.error = false
            this.$bvModal.hide('modal-1')
          } else {
            this.error = true
            this.message = response.data.message
          }
        }
      })
        .catch((error) => {
          console.log(error)
          this.isBusyCreate = false
        })
    },
    submitSearch(evt) {
      evt.preventDefault()
      this.$refs.table.refresh()
    },
    resetSearch(evt) {
      evt.preventDefault()
      this.searchQuery.query = ''
      this.searchQuery.tags = []
      this.$refs.table.refresh()
    },
    tableDataProvider(ctx) {
      const promise = this.axios.get(process.env.VUE_APP_BACKEND_URL + '/admin/api/files?page=' + ctx.currentPage + '&perpage=' +
        ctx.perPage + (this.searchQuery.query === null ? '' : '&query=' + this.searchQuery.query) + '&parentId=' + this.form.folder.parentId, this.$store.getters.getAxiosConfig)
      return promise.then(response => {
        this.items = response.data.files
        this.folders = response.data.folders
        this.totalRows = response.data.totalRows

        return this.items || []
      }).catch((error) => {
        console.log(error)
        this.items = []
        this.totalRows = 0

        return this.items || []
      })
    },
    remove(id) {
      if (confirm('Удалить файл #' + id + '?')) {
        this.error = false
        this.axios.delete(process.env.VUE_APP_BACKEND_URL + '/admin/api/files/' + id, this.$store.getters.getAxiosConfig).then((response) => {
          if (response) {
            if (response.data.error === false) {
              this.error = false
              this.$refs.table.refresh()
            } else {
              console.log(response.data.message)
            }
          }
        })
      }
    },
    edit(item) {
      this.isUpdate = true
      this.form.id = item.id
      this.form.name = item.name
      this.form.url = item.url
      this.form.folder.selectId = this.currentFolderId
      this.$bvModal.show('modal-1')
    },
    validURL(str) {
      let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
      return !!pattern.test(str)
    },
    selectUpload() {
      this.uploadSelected = true
      this.$refs.filebrowse.$el.childNodes[0].click()
    },
    startUpload() {
      this.reader = new FileReader()
      this.uploadInProgress = true
      this.percent_done = 1
      this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/files/upload/start', {file_name: this.file.name}, this.$store.getters.getAxiosConfig)
        .then((response) => {
          if (response.data.error) {
            this.error = true
            this.message = response.data.message
            console.log(response.data.message)
            this.resetUpload()
          } else if (response.data.key) {
            this.key = response.data.key
            this.upload_id = response.data.upload_id
            this.upload_part(0)
            this.percent_done = 2
          }
        })
        .catch((reason) => {
          console.log(reason)
          this.resetUpload()
        })
    },
    upload_part: function(start) {
      const next_slice = start + part_size + 1
      const blob = this.file.slice(start, next_slice)
      const self = this
      this.reader.onloadend = function(event) {
        if (event.target.readyState !== FileReader.DONE) {
          return
        }
        self.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/files/upload/part', {
          file_data: event.target.result,
          file_name: self.file.name,
          file_type: self.file.type,
          chunk_num: self.chunkNum,
          parts: self.parts,
          upload_id: self.upload_id,
          key: self.key,
        }, self.$store.getters.getAxiosConfig)
          .then((response) => {
            if (response.data.error) {
              self.error = true
              self.message = response.data.message
              console.log(response.data.message)
              this.resetUpload()
            } else if (response.data.parts) {
              self.chunkNum = self.chunkNum + 1
              let size_done = start + part_size
              self.percent_done = Math.floor((size_done / self.file.size) * 100)
              self.parts = response.data.parts
              if (next_slice < self.file.size) {
                self.upload_part(next_slice)
              } else {
                self.finish_upload()
              }
            }
          })
          .catch((reason) => {
            console.log(reason)
            self.uploadInProgress = false
            self.resetUpload()
          })
      }

      this.reader.readAsDataURL(blob)
    },
    finish_upload: function() {
      this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/files/upload/finish', {
        file_name: this.file.name,
        key: this.key,
        parts: this.parts,
        upload_id: this.upload_id,
      }, this.$store.getters.getAxiosConfig)
        .then((response) => {
          if (response.data.error) {
            this.error = true
            this.message = response.data.message
            console.log(response.data.message)
          } else if (response.data.url) {
            this.percent_done = 100
            this.form.url = response.data.url
          }
          this.resetUpload()
        })
    },
    resetUpload() {
      this.uploadInProgress = false
      this.percent_done = 0
      this.uploadSelected = false
      this.reader = null
      this.chunkNum = 1
      this.parts = {Parts: []}
      this.key = ''
      this.upload_id = ''
      this.percent_done = 0
      this.file = null
    },
  },
}
</script>

<style scoped>

</style>
